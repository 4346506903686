<template>
    <div>
        <v-container>
            <div class="">
                <div class="d-flex justify-center align-center">
                    <v-icon size="6rem" color="red">mdi-alert-circle</v-icon>
                </div>
                <div class="text-center mt-3">
                    <h3>Your payment has failed.</h3>
                    <p>Unfortunately, we could not process your payment. Please try again or contact support for assistance.</p>
                </div>
            </div>
        </v-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loaded: false,
        }
    },
    methods: {
    },
    mounted() {
    }
}
</script>


